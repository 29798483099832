
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import ActionButton from "@/components/Button/ActionButton.vue";
import { IStudent } from "@/interfaces/Student/IStudent";
import { PropType } from "vue";
import Avatar from "@/components/Avatars/Avatar.vue";
import { formatStudentName } from "@/utils/NameUtil";
import { IBillingAddress } from "@/interfaces/Student/IBillingAddress";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";

@Component({
  components: { SaveButton, AbortButton, Avatar, ActionButton },
})
export default class StudentCard extends Vue {
  public name = "StudentCard";

  @Prop({ type: Object as PropType<IStudent>, required: false })
  public student?: IStudent;

  @Prop({ type: Object as PropType<IBillingAddress>, required: false })
  public billingAddress?: IBillingAddress;

  @Prop({ type: Boolean, required: false, default: () => false })
  public isLoading!: boolean;

  @Inject({ from: "onArchiveStudent", default: () => null })
  private onArchiveStudent!: (option: { id: number; archived: boolean }) => Promise<void>;

  private get studentId() {
    return this.student?.id;
  }

  private get studentIsArchived() {
    return this.student?.archived;
  }

  private toggleArchiveAction() {
    if (this.student?.archived === true) {
      this.onUnArchiveStudentModalShow();
    } else if (this.student?.archived === false) {
      this.onArchiveStudentModalShow();
    }
  }

  private calculateYears(birthYear: any) {
    let newAge = Date.now() - birthYear.getTime();
    let currentAge = new Date(newAge);
    return Math.abs(currentAge.getUTCFullYear() - 1970);
  }

  private get studentBirthDate(): string {
    return this.student
      ? `${this.$options.filters?.formatDate(this.student.birthDate)} (${this.calculateYears(new Date(this.student.birthDate || 0))}) | ${
          this.student.birthPlace
        }`
      : "";
  }

  private get studentEmail() {
    return this.student?.email;
  }
  private get studentVisualAid() {
    return this.student?.visualAid;
  }

  private get studentFullName(): string {
    return this.student ? formatStudentName(this.student.firstName, this.student.lastName) : "";
  }

  private get studentPrivatePhone() {
    return this.student?.privatePhone;
  }
  private get studentMobilePhone() {
    return this.student?.mobilePhone;
  }
  private get studentWhatsAppChatLink() {
    return this.student?.whatsAppChatLink;
  }

  private modalStudentArchivePopover = "modalStudentArchivePopover";
  private modalStudentUnArchivePopover = "modalStudentUnArchivePopover";
  private firstArchivePreventMessageVisible = true;

  private onUnArchiveStudentModalShow() {
    this.$bvModal.show(this.modalStudentUnArchivePopover);
  }

  private onArchiveStudentModalShow() {
    this.firstArchivePreventMessageVisible = true;
    this.$bvModal.show(this.modalStudentArchivePopover);
  }

  private onStudentArchiveAbort() {
    this.firstArchivePreventMessageVisible = true;
    this.$bvModal.hide(this.modalStudentArchivePopover);
  }

  private async onStudentArchive(): Promise<void> {
    if (this.firstArchivePreventMessageVisible) {
      this.firstArchivePreventMessageVisible = false;
      return;
    }
    this.$bvModal.hide("modalStudentArchivePopover");
    const studentId = this.studentId;
    this.firstArchivePreventMessageVisible = true;
    if (studentId) {
      await this.onArchiveStudent({
        id: studentId,
        archived: true,
      });
    }
  }

  private async onStudentUnArchive(): Promise<void> {
    this.$bvModal.hide(this.modalStudentUnArchivePopover);
    const studentId = this.studentId;
    if (studentId) {
      await this.onArchiveStudent({
        id: studentId,
        archived: false,
      });
    }
  }

  private handleOnMobilePhoneDoubleClick() {
    if (this.studentWhatsAppChatLink) {
      window.open(this.studentWhatsAppChatLink, '_blank');
    }
  }
}
