
import { Vue, Component, Prop, InjectReactive, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { IStudent } from "@/interfaces/Student/IStudent";
import { PropType } from "vue";
import { IStudentEducation } from "@/interfaces/Student/IStudentEducation";
import { IAbibaroStatus } from "@/interfaces/Student/IAbibaroStatus";
import { ISaldo } from "@/interfaces/Student/ISaldo";
import CustomSlider from "@/components/Slider/CustomSlider.vue";
import { TranslateResult } from "vue-i18n";
import { IExamAndEducation } from "@/interfaces/Exam/IExamAndEducation";
import StudentDriveBuzzAbibaroStatusCard from "@/views/Student/Overview/StudentDriveBuzzAbibaroStatusCard.vue";
import StudentCard from "@/views/Student/Overview/StudentCard.vue";
import StudentEducation from "@/views/Student/Overview/StudentEducation.vue";
import StudentBalanceTop from "@/views/Student/Overview/StudentBalanceTop.vue";
import StudentBalanceCard from "@/views/Student/Overview/StudentBalanceCard.vue";
import StudentAppointments from "@/views/Student/Overview/StudentAppointments.vue";
import StudentChecks from "@/views/Student/Overview/StudentChecks.vue";
import StudentBalance from "@/views/Student/Overview/StudentBalance.vue";
import MandateInfo from "@/views/Student/Overview/MandateInfo.vue";
import StudentArchive from "@/views/Student/Overview/StudentArchive.vue";

@Component({
  components: {
    StudentArchive,
    MandateInfo,
    StudentBalance,
    StudentChecks,
    StudentAppointments,
    StudentEducation,
    CustomSlider,
    StudentBalanceTop,
    StudentBalanceCard,
    StudentDriveBuzzAbibaroStatusCard,
    StudentCard,
    FscSimpleCard,
  },
})
export default class StudentOverview extends Vue {
  public name = "StudentOverview";

  // Top Tabs
  @InjectReactive({ from: "topTab", default: () => 0 })
  private topTab!: number;

  @InjectReactive({ from: "updateTopTab", default: (index: number) => null })
  private updateTopTab!: (index: number) => void;

  private get topTabProxy() {
    return this.topTab;
  }
  private set topTabProxy(value: number) {
    this.updateTopTab(value);
  }

  // Bottom Tabs
  @InjectReactive({ from: "bottomTab", default: () => 0 })
  private bottomTab!: number;

  @InjectReactive({ from: "updateBottomTab", default: (index: number) => null })
  private updateBottomTab!: (index: number) => void;

  private get bottomTabProxy() {
    return this.bottomTab;
  }
  private set bottomTabProxy(value: number) {
    this.updateBottomTab(value);
  }

  private activeLearnStatusIndex = 0;

  @Prop({ type: Object as PropType<IStudent> })
  public student!: IStudent;

  @Prop({ type: Array as PropType<Array<IStudentEducation>> })
  public studentEducations!: Array<IStudentEducation>;

  @Prop({ type: Array as PropType<Array<IAbibaroStatus>> })
  public studentEducationsAbibaroStatus!: Array<IAbibaroStatus>;

  @Prop({ type: Array as PropType<Array<ISaldo>> })
  public allSaldo!: Array<ISaldo>;

  @Prop({ type: Boolean })
  public registeredInAbibaro!: boolean;

  @Prop({ type: Function, default: () => null })
  public onSubmitBalanceTop!: (payment: any) => void;

  // @Prop({ type: Array as PropType<Array<IAppointment>>, default: () => [] })
  // public appointments!: Array<IAppointment>;

  @Prop({ type: Array as PropType<Array<IExamAndEducation>>, default: () => [] })
  public examAndEducations!: Array<IExamAndEducation>;

  @Prop({ type: Boolean, default: () => false })
  public studentCardLoading!: boolean;

  @Prop({ type: Boolean, default: () => false })
  public studentDriveBuzzAbibaroLoading!: boolean;

  @Prop({ type: Boolean, default: () => false })
  public saldoLoading!: boolean;

  @Prop({ type: Boolean, default: () => false })
  public studentEducationLoading!: boolean;

  @Prop({ type: Boolean, default: () => false })
  public appointmentsLoading!: boolean;

  @Prop({ type: Boolean, default: () => false })
  public studentChecksLoading!: boolean;

  @Prop({ type: Boolean, default: () => false })
  public mandateLoading!: boolean;

  @Prop({ type: Boolean, default: () => false })
  public studentArchiveLoading!: boolean;

  @Prop({ type: Function, default: () => null })
  public onArchiveEducation!: (studentEducationId: number) => Promise<void>;

  @Prop()
  public appointmentsCount!: any;

  private driveBuzzLoginAllowed = false;

  private get billingAddress() {
    return this.studentEducations?.[this.activeLearnStatusIndex]?.billingAddress;
  }

  private get learnStatus() {
    return this.studentEducations?.[this.activeLearnStatusIndex]?.learnStatus;
  }

  private get studentId() {
    return this.student?.id;
  }

  private get studentEducationAbibaroStatus() {
    return this.studentEducationsAbibaroStatus?.[this.activeLearnStatusIndex];
  }

  private get totalSaldo() {
    return this.allSaldo.filter((saldo: any) => saldo.licenseClass === null)?.[0] || undefined;
  }

  private onAbortBalanceTop() {
    this.updateTopTab(0);
  }
  //
  // public get allAppointmentsLength() {
  //   let current_time = moment().format().slice(0, 10);
  //   let futureAppointments = this.appointments.filter((appointment: any) => appointment.date >= current_time);
  //   return futureAppointments.length;
  // }

  private get tabs(): Array<{ label: string | TranslateResult }> {
    if (this.student && this.student?.archived) {
      return [
        {
          label: this.$t("sidebar.training"),
        },
        {
          label: `${this.$tc("general.event", 2)} (${this.appointmentsCount})`,
        },
        {
          label: this.$t("general.checks"),
        },
        {
          label: this.$t("general.balance"),
        },
        {
          label: this.$t("general.mandate"),
        },
        {
          label: "Archiv",
        },
      ];
    } else {
      return [
        {
          label: this.$t("sidebar.training"),
        },
        {
          label: `${this.$tc("general.event", 2)} (${this.appointmentsCount})`,
        },
        {
          label: this.$t("general.checks"),
        },
        {
          label: this.$t("general.balance"),
        },
        {
          label: this.$t("general.mandate"),
        },
      ];
    }
  }

  private get licenceClasses() {
    return this.studentEducations.reduce(
      (previousValue: Array<any>, currentValue: IStudentEducation) => {
        if (currentValue.mainLicenseClass) {
          previousValue.push({
            label: currentValue.mainLicenseClass,
            value: currentValue.mainLicenseClass,
          });
        }
        return previousValue;
      },
      [{ label: this.$t("todo.all"), value: null }]
    );
  }

  @Watch("student", { deep: true, immediate: true })
  private onChangeStudent(student: IStudent) {
    if (student?.id) {
      this.driveBuzzLoginAllowed = student.driveBuzzLoginAllowed;
    }
  }
}
